<template>
  <div>
    <v-skeleton-loader v-if="!data_loaded" type="table"></v-skeleton-loader>
    <ProductsFilter
      v-if="data_loaded"
      :loading_excel="loading_excel"
      @sections_changed="sections_changed($event)"
      :filter_products="filter_products"
      @update_data="get_data()"
    ></ProductsFilter>

    <v-card v-if="data_loaded">
      <v-card-title>
        <div class="d-flex align-center">
          <v-icon x-large>inventory_2</v-icon>
          <h1 class="ms-3">מוצרים</h1>
        </div>
        <v-spacer></v-spacer>
        <div class="d-flex align-center">
          <v-text-field
            hide-details
            outlined
            dense
            :disabled="loading_excel"
            v-model="filter_products.search_key"
            @keyup.enter="searchData()"
            @keyup.backspace="clearSearch()"
            @click:clear="(filter_products.search_key = ''), clearSearch()"
          ></v-text-field>
        </div>
        <v-spacer></v-spacer>
        <div class="d-flex align-center">
          <div class="custom-file-input mx-3">
            <input
              type="file"
              accept=".xlsx,.xls"
              id="file-input"
              hidden
              ref="fileInput"
              class="file-input"
              @change="updateExcel($event)"
            />

            <v-btn
              depressed
              text
              :loading="loading_excel"
              :disabled="loading_excel"
              class="px-0"
            >
              <label for="file-input" class="file-label">
                <img src="@/assets/excel.png" alt="" />
              </label>
            </v-btn>
          </div>

          <v-dialog v-model="totalProductsDialog" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="totalProductLoading || loading_excel"
                :loading="totalProductLoading"
                @click="totalProducts()"
                color="primary"
                :diabled="loading_excel"
                fab
                depressed
                small
              >
                <v-icon>attach_money</v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="justify-space-around text-center py-5">
                <div>
                  <h3 class="mb-1">כמות מוצרים</h3>
                  {{ totals.count_products }}
                </div>
                <div>
                  <h3 class="mb-1">סך כל המוצרים</h3>
                  {{ totals.total_products }}
                </div>
              </v-card-title>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="totalProductsDialog = false"
                >
                  סגור
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn
            :loading="loading_excel && !importExcel"
            :disabled="loading_excel"
            @click="download_excel()"
            icon
            class="mx-3"
          >
            <v-icon>download</v-icon>
          </v-btn>
          <v-btn
            v-if="$store.getters.permission('products create')"
            to="/product"
            color="primary"
            :disabled="loading_excel"
            depressed
            >מוצר חדש</v-btn
          >
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          class="text-center"
          :headers="headers"
          :items="items"
          mobile-breakpoint="0"
          disable-pagination
          hide-default-footer
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <div class="d-flex align-center">
                  <img
                    v-if="item.img"
                    :src="`${$store.state.def_path}/${item.img}`"
                    width="50"
                    height="50"
                    alt=""
                  />
                  <img
                    width="50"
                    height="50"
                    v-if="!item.img"
                    :src="`${$store.state.def_path}/uploads/none_img.png`"
                    alt=""
                  />

                  <v-chip class="mx-2" small color="error" v-if="!item.quantity">
                    {{ item.name }}
                  </v-chip>
                  <span v-else class="mx-2">
                    {{ item.name }}
                  </span>
                </div>
              </td>
              <td>{{ item.barcode }}</td>
              <td>{{ item.price }}</td>
              <td>{{ item.quantity }}</td>
              <td>{{ item.quantity_alert }}</td>
              <td>{{ item.sku || "_" }}</td>
              <td>{{ item.sort || "_" }}</td>
              <td>
                <v-chip
                  v-if="item.status == 1"
                  x-small
                  class="px-2"
                  color="success black--text"
                >
                  פעיל
                </v-chip>

                <v-chip
                  v-else-if="item.status == 0"
                  x-small
                  class="px-2"
                  color="error"
                >
                  לא פעיל
                </v-chip>

                <v-chip
                  v-else-if="item.status == 2"
                  x-small
                  class="px-2"
                  color="blue-grey white--text"
                >
                  חסוי
                </v-chip>
              </td>
              <td>{{ item.created_at }}</td>
              <td>
                <v-btn @click="download_barcode(item.id)" icon small>
                  <barcode
                    class="d-none"
                    :id="`barcode_${item.id}`"
                    :value="item.barcode"
                  >
                  </barcode>
                  <v-icon small>qr_code_scanner</v-icon>
                </v-btn>
                <v-btn
                  v-if="$store.getters.permission('products edit')"
                  :to="`product/${item.id}`"
                  small
                  icon
                >
                  <v-icon small>edit</v-icon>
                </v-btn>
                <v-btn
                  v-if="$store.getters.permission('products delete')"
                  @click="delete_row(item.id)"
                  small
                  icon
                >
                  <v-icon small>delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <div
      v-if="data_loaded"
      class="text-center mt-4 d-flex justify-center mb-10"
    >
      <v-pagination
        @input="change_page()"
        v-model="page"
        total-visible="7"
        :length="total"
        circle
      ></v-pagination>
      <div class="mr-2 pt-2">
        <v-select
          :value="perpage"
          :items="pagination"
          style="width: 70px"
          hide-details
          dense
          @change="change_page()"
        ></v-select>
      </div>
    </div>
    <v-snackbar
      :color="excelAlertSuccess ? '#2e7d32' : 'error'"
      width="auto"
      left
      v-model="excelAlert"
    >
      {{ excelAlertText }}
      <template v-slot:action="{ attrs }">
        <v-icon color="white" v-bind="attrs">check_circle</v-icon>
      </template>
    </v-snackbar>
    <DeleteDialog
      @delete_done="get_data()"
      @close_delete="delete_obj.delete_prop = false"
      :delete_obj="delete_obj"
    ></DeleteDialog>
  </div>
</template>

<script>
import DeleteDialog from "@/components/public/delete";
import ProductsFilter from "@/components/products/ProductsFilter";

import VueBarcode from "vue-barcode";
export default {
  name: "Table",
  data() {
    return {
      excelAlert: false,
      excelAlertText: "",
      importExcel: false,
      excelAlertSuccess: true,
      delete_obj: {
        delete_prop: false,
        delete_url: "products",
        title: "מחיקת מוצר",
        id: null,
      },
      loading_excel: false,
      search_results: [],
      data_loaded: false,
      total: 0,
      page: 1,
      section_menu: false,
      pagination: [20, 30, 50],
      perpage: 20,
      headers: [
        {
          value: "name",
          text: "שם מוצר",
          align: "center",
        },

        {
          value: "barcode",
          text: "ברקוד",
          align: "center",
        },
        {
          value: "price",
          text: "price",
          align: "center",
        },
        {
          value: "quantity",
          text: "כמות",
          align: "center",
        },
        {
          value: "quantity_alert",
          text: "כמות להתראה",
          align: "center",
        },
        {
          value: "sku",
          text: "מספר מדף",
          align: "center",
        },
        {
          value: "sort",
          text: "sort",
          align: "center",
        },
        {
          value: "status",
          text: "סטטוס",
          align: "center",
        },
        {
          value: "created_at",
          text: "תאריך הוספה",
          align: "center",
        },
        {
          value: "actions",
          text: "פעולות",
          align: "center",
        },
      ],
      items: [],
      product: {
        tags: [],
      },
      items_box: [],
      search: null,
      sections: [],
      filter_products: {
        sections: [],
        brands: [],
        status: [],
        search_key: "",
        quantity: false,
        excel: false,
      },
      temp_sections: [],
      totalProductsDialog: false,
      totalProductLoading: false,
      totals: {
        count_products: 0,
        total_products: 0,
      },
    };
  },
  components: {
    DeleteDialog,
    barcode: VueBarcode,
    ProductsFilter,
  },
  watch: {
    "filter_products.sections": {
      handler(ev) {
        this.get_data();
      },
      deep: true,
    },
  },
  methods: {
    sections_changed(ev) {
      if (ev) {
        this.temp_sections = JSON.parse(
          JSON.stringify(this.filter_products.sections)
        );
        console.log(this.temp_sections);
      } else {
        let temp = JSON.stringify(this.temp_sections);
        let f_sections = JSON.stringify(this.filter_products.sections);
        if (temp != f_sections) {
          this.get_data();
        }
      }
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;
      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    },
    change_page() {
      this.data_loaded = false;
      this.get_data();
    },
    download_barcode(id) {
      var canvas = document.getElementById(`barcode_${id}`).firstElementChild;
      var serializer = new XMLSerializer();
      var source = serializer.serializeToString(canvas);
      source = '<?xml version="1.0" standalone="no"?>\r\n' + source;
      var url =
        "data:image/png+xml;charset=utf-8," + encodeURIComponent(source);
      var link = document.createElement("a");
      link.download = "qr_code.svg";
      link.href = url;
      link.click();
    },
    get_data() {
      this.filter_products.excel = false;
      this.$store
        .dispatch("public__request", {
          config: {
            url: `get_products?page=${this.page}&per_page=${this.perpage}`,
            method: "post",
          },
          data: this.filter_products,
        })
        .then((res) => {
          const data = res.data.meta;
          this.data_loaded = true;
          this.items = Object.assign([], res.data.data);
          this.total = data.last_page;
          this.page = data.current_page;
        });
    },
    delete_row(id) {
      this.delete_obj.id = id;
      this.delete_obj.delete_prop = true;
    },

    search_add_to_filter(item) {
      if (this.filter_products.sections.includes(item.id)) {
        let ind = this.filter_products.sections.findIndex(
          (e) => e.id == item.id
        );
        this.filter_products.sections.splice(ind, 1);
      } else {
        this.filter_products.sections.push(item.id);
      }
    },
    download_excel() {
      this.filter_products.excel = true;
      this.loading_excel = true;
      this.axios({
        url: "/get_products",
        method: "post",
        responseType: "blob",
        headers: {
          // "Content-type": "application/vnd.ms-excel",
          Authorization: `Bearer ${this.$store.state.token}`,
        },
        data: this.filter_products,
      })
        .then((res) => {
          var today = new Date();
          var dd = String(today.getDate()).padStart(2, "0");
          var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
          var yyyy = today.getFullYear();

          today = mm + "/" + dd + "/" + yyyy;
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `product_${today}.xls`);
          document.body.appendChild(link);
          link.click();
          this.loading_excel = false;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    totalProducts() {
      if (!this.totalProductLoading) {
        this.totalProductLoading = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: `totalProducts`,
              method: "get",
            },
          })
          .then((res) => {
            this.totals = res.data.data;
            this.totalProductsDialog = true;
            this.totalProductLoading = false;
          });
      }
    },
    searchData() {
      if (this.filter_products.search_key) {
        this.get_data();
      }
    },
    clearSearch() {
      if (!this.filter_products.search_key) {
        this.get_data();
      }
    },
    async updateExcel(file) {
      const uploadedFile = file.target.files[0];
      if (!this.loading_excel && !this.importExcel && uploadedFile) {
        this.loading_excel = true;
        this.importExcel = true;

        const formData = new FormData();
        formData.append("excelFile", uploadedFile);
        await this.$store
          .dispatch("public__request", {
            config: {
              url: `importProducts`,
              method: "post",
            },
            data: formData,
          })
          .then((res) => {
            this.clearUpdateExcel(true);
          })
          .catch((err) => {
            this.clearUpdateExcel(false);
          });
      }
    },
    clearUpdateExcel(isSuccess) {
      this.loading_excel = false;
      this.excelAlertSuccess = isSuccess;
      this.excelAlert = true;
      this.importExcel = false;
      this.excelAlertText = isSuccess
        ? " تم تحديث بيانات ملف الاكسيل نجاح"
        : "يوجد مشكلة في الملف المرفوع";
      this.$refs.fileInput.value = null;
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>

<style lang="scss" scoped>
.basic {
  .col {
    flex: 0 0 20%;
    max-width: 20%;
    @media (max-width: 991px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}

.custom-file-input {
  overflow: hidden;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  position: relative;
  top: 5px;
}

.file-label {
  display: inline-block;
  padding: 10px;
  cursor: pointer;
}
</style>
