<template>
  <v-container fluid>
    <div class="d-block d-md-flex">
      <AppAside></AppAside>
      <div class="ps-5 page_content grow">
        <Table></Table>
      </div>
    </div>
  </v-container>
</template>

<script>
import Table from "@/components/products/table";

export default {
  name: "products_page",
  data() {
    return {
      form: false,
    };
  },
  components: {
    Table,

  },
};
</script>
