<template>
  <v-card flat :disabled="loading_excel">
    <v-row>
      <v-col cols="3">
        <v-select
          label="סטטוס"
          outlined
          dense
          multiple
          background-color="white"
          :items="statuses"
          @change="$emit('update_data')"
          v-model="filter_products.status"
        >
        </v-select>
      </v-col>
      <v-col cols="3">
        <div class="white mb-3">
          <v-menu
            :close-on-content-click="false"
            offset-y
            v-model="section_menu"
            min-width="300"
            max-width="300"
            scrollable
            @input="$emit('sections_changed', $event)"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                v-bind="attrs"
                dense
                hide-details
                v-on="on"
                readonly
                v-model="sections_input"
              >
                <template slot="label"> קטגוריות </template>
              </v-text-field>
            </template>
            <v-expansion-panels
              accordion
              style="max-height: 500px; overflow-y: auto"
            >
              <v-expansion-panel
                v-for="(item, index) in sections_ini"
                :key="index"
              >
                <v-expansion-panel-header
                  @click="open_section(item, index)"
                  disable-icon-rotate
                  :class="{ primary: item.num_padding != 0 }"
                >
                  <template v-slot:actions>
                    <v-icon :class="{ 'white--text': item.num_padding != 0 }">{{
                      item.checked ? "add" : "remove"
                    }}</v-icon>
                  </template>
                  <span :class="{ 'white--text': item.num_padding != 0 }">
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon
                            :class="{ 'white--text': item.num_padding != 0 }"
                            >error_outline</v-icon
                          ></v-btn
                        >
                      </template>
                      <span>{{ item.name }}</span>
                    </v-tooltip>

                    <v-chip x-small class="me-2">
                      {{ item.num_padding }}
                    </v-chip>
                    {{ item.name.slice(0, 18) }}
                    {{ item.name.length >= 18 ? "..." : "" }}
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list color="grey lighten-4">
                    <v-list-item @click="select_all(index, item)">
                      <v-list-item-content> الكل </v-list-item-content>
                      <v-list-item-action>
                        <v-checkbox v-model="item.all"></v-checkbox>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item
                      v-for="(child, i) in item.children"
                      :key="i"
                      class="child_list mb-3"
                      @click="
                        (child.checked = !child.checked),
                          select_section(item, child)
                      "
                    >
                      <v-list-item-content>
                        {{ child.name.slice(0, 18) }}
                        {{ child.name.length >= 18 ? "..." : "" }}
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-checkbox v-model="child.checked"></v-checkbox>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-menu>
        </div>
      </v-col>
      <v-col cols="3">
        <v-combobox
          :items="brands"
          item-text="name"
          item-value="id"
          dense
          hide-details
          multiple
          background-color="white"
          :return-object="false"
          outlined
          label="מותגים"
          :value="brands_val"
          @blur="$emit('update_data')"
          @change="compochange('brands', $event)"
        >
        </v-combobox>
      </v-col>
      <v-col cols="3">
        <v-checkbox
          @change="$emit('update_data')"
          label="להזמנה"
          v-model="filter_products.quantity"
        ></v-checkbox>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "ProductsFilter",
  props: ["filter_products", "loading_excel"],
  data() {
    return {
      sections: [],
      brands: [],
      section_menu: false,
      statuses: [
        {
          value: 1,
          text: "פעיל",
        },
        {
          value: 0,
          text: "לא פעיל",
        },
        {
          value: 2,
          text: "חסוי",
        },
      ],
    };
  },
  computed: {
    brands_val() {
      return this.brands.filter((item) =>
        this.filter_products.brands.includes(item.id)
      );
    },
    sections_input() {
      let names = [];
      this.sections.forEach((ev, i) => {
        ev.children.forEach((ch, s) => {
          if (this.filter_products.sections.includes(ch.id)) {
            names.push(ch.name);
          }
        });
      });
      return names.length > 1 ? ` (${names.length})  اقسام` : names[0];
    },
    children_sections() {
      let sections = [];
      this.sections.forEach((ev, i) => {
        ev.children.forEach((ch, s) => {
          sections.push(ch);
          if (this.filter_products.sections.includes(ch.id)) {
            this.search_results.push(ch);
          }
        });
      });
      return sections;
    },
    sections_ini() {
      let sections = [];
      this.sections.forEach((ev, i) => {
        let padding = 0;
        ev.children.forEach((ch, s) => {
          if (this.filter_products.sections.includes(ch.id)) {
            ch.checked = true;
            padding++;
          } else {
            ch.checked = false;
          }
        });
        ev.num_padding = padding;
        if (padding == ev.children.length) {
          ev.all = true;
        }
        sections.push(ev);
      });
      return sections;
    },
  },
  methods: {
    get_sections() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "sections_filter",
            method: "get",
          },
        })
        .then((res) => {
          this.sections = Object.assign([], res.data.data);
        });
    },
    get_brands() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "brands",
            method: "get",
          },
        })
        .then((res) => {
          this.brands = Object.assign([], res.data.data);
        });
    },
    open_section(item, index) {
      this.sections.forEach((ev, ind) => {
        if (index != ind) {
          this.sections[ind].checked = false;
        }
      });
      item.checked = !item.checked;
    },
    select_all(index, item) {
      item.all = !item.all;
      if (item.all) {
        item.children.forEach((e) => {
          this.filter_products.sections.push(e.id);
        });
      } else {
        const map = item.children.map(function (val) {
          return val.id;
        });

        const removeItems = (array, itemsToRemove) => {
          return array.filter((v) => {
            return !itemsToRemove.includes(v);
          });
        };
        this.filter_products.sections = removeItems(
          this.filter_products.sections,
          map
        );
      }
    },

    search_sections(ev) {
      let map = this.search_results.map(function (val) {
        return val.id;
      });
      this.filter_products.sections = map;
    },
    select_section(parent, item) {
      if (item.checked) {
        this.filter_products.sections.push(item.id);
      } else {
        let id_index = this.filter_products.sections.findIndex(
          (e) => e == item.id
        );
        parent.all = false;
        this.filter_products.sections.splice(id_index, 1);
      }
    },

    compochange(prop, val) {
      this.filter_products[prop] = val;
    },
  },
  mounted() {
    this.get_sections();
    this.get_brands();
  },
};
</script>
